/* Declarations
---------------------------------- */
$font-primary: 'Noto Sans', sans-serif;
$font-secondary: 'Playfair Display', serif;
$font-tertiary: 'Poppins', sans-serif;
$font-action: 'Sen', sans-serif;
$icon: 'Trilogy-Icons';


$color-text-primary: #222;
$color-text-secondary: #000000;

$color-link: $blue;
$color-link-rollover: $red;

$color-background-primary: #222222;
$color-background-secondary: #f1f1f1;
$color-background-tertiary: #86002b;
$color-background-primary-rgb: 34,34,34;
$color-background-secondary-rgb: 241,241,241;
$color-background-tertiary-rgb: 134,0,43;


@font-face {
   font-family: 'Trilogy-Icons';
   src:  url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.eot?4ywpzi');
   src:  url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.eot?4ywpzi#iefix') format('embedded-opentype'),
      url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.woff2?4ywpzi') format('woff2'),
      url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.ttf?4ywpzi') format('truetype'),
      url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.woff?4ywpzi') format('woff'),
      url('https://s3.amazonaws.com/icomoon.io/31537/Trilogy/Trilogy-Icons.svg?4ywpzi#Trilogy-Icons') format('svg');
   font-weight: normal;
   font-style: normal;
}

.icons {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'Trilogy-Icons' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html {
  font-family: $font-primary;
  color: $color-text-primary;
  line-height: 1.2; 
  font-weight: 500;
  font-style: normal;
  font-size: 100%; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

@media only screen and (min-width: 740px) {  
   html {
	  font-size: 100%; }
}

strong {
  font-weight: 700; }


/* Global Links
---------------------------------- */
a {
   color: $color-link;
   text-decoration: none; 
   position: relative;
   -webkit-transition: all .15s ease-in-out;
   -moz-transition:    all .15s ease-in-out;
   -o-transition:      all .15s ease-in-out;
   -ms-transition:     all .15s ease-in-out;
   transition:         all .15s ease-in-out; }

a:focus, 
a:hover {
   color: $color-link-rollover;
   outline: none; }

/* hyperlinks in content */
.content a::before {
   height: 6px;
   width: 100%;
   content: ' ';
   position: absolute;
   bottom: 2px;
   z-index: -1;
   -webkit-transition: all .15s ease-in-out;
   -moz-transition:    all .15s ease-in-out;
   -o-transition:      all .15s ease-in-out;
   -ms-transition:     all .15s ease-in-out;
   transition:         all .15s ease-in-out;  }
  
.content a:focus::before,
.content a:hover::before{
   height: 9px;
   width: 100%;
   content: ' ';
   position: absolute;
   bottom: 0;
   z-index: -1; }


/* Firefox Seleted Text
------------------------------------ */
*::-moz-selection,
*::selection {
  background: red;
  color: white; }


/* Headings - 400/700
------------------------------------ */

h1, h2, h3 { 
   font-style: normal;
   font-weight: 900;
   color: $black;
   font-family: $font-secondary; }

h4, h5, h6 { 
   font-style: normal;
   font-weight: 900;
   color: $black;
   font-family: $font-tertiary; }

h1 {
   font-size: 200%;
   margin-bottom: .25em;
   line-height: 1.25; }

@media only screen and (min-width: 360px)  { h1 { font-size: 220%; } }
@media only screen and (min-width: 740px)  { h1 { font-size: 250%; } }
@media only screen and (min-width: 1400px) { h1 { font-size: 300%; } }

h2 {
  font-size: 160%;
  line-height: 1.25;
  margin-bottom: .25em }

@media only screen and (min-width: 740px)  { h2 { font-size: 200%; } }

h3 {
  font-size: 140%;
  line-height: 1.25;
  margin-bottom: .5em  }

@media only screen and (min-width: 740px)  { h3 { font-size: 160%; } }

h4 {
	font-size: 110%;
	margin-bottom: .5em }

h5 {
    padding: 0 0 .2em;
    font-size: 80%;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 700; }

h1 a,
h2 a { display: inline-block; }


/* Text Elements
---------------------------------- */
p { 
	margin: 0 0 1em; }

p:last-child { 
   margin-bottom: 0; }

em { font-style: italic; }

small { 
   font-size: 85%; 
   display: block; }
.meta small { 
   text-transform: uppercase; 
   padding-bottom: .75em; }
blockquote {
   font-weight: 300; }
strong, b { 
   font-weight: 600; }

sup { 
   vertical-align: super;
   font-size: smaller; }

.footnotes { 
   font-size: smaller;
   padding-top: 1.5em; }

.emphasis {
   font-size: 120%;
   font-weight: 700;
   background: #ffcc00;
   padding: .2em;
   display: inline-block; }


/* Lists
---------------------------------- */
main ul,
section ul,
main ol,
section ol      { margin: 0 0 1.5em 2em; }

main li,
section li      { margin-bottom:.25em; }

.button {
	background: $primary;
   color: $white !important;
	border-radius: 100px;
	padding: .75rem 1.5rem;
	font-family: $button;
   font-size: 1.0rem;
   font-weight: 900;
   text-transform: uppercase;
	display: inline-block !important;
	position: relative;
	overflow: hidden;
	transition: $bounce;
	margin: 0;
	outline: 0;
	text-decoration: none !important;
	&.small {
		padding: 1rem 2.5rem;
	}	
	@include tablet {
		padding: 1rem 2.5rem;
      letter-spacing: -.5px;
		margin: 0;
		&.small {
			padding: 1rem 2.5rem;
		}
	}		
	@include md {
      padding: 1rem 2.5rem;
		margin: 0;	
		&.small {
			padding: 1rem 2.5rem;
		}		
	}		
	&:before,
	&:after {
		@include size(100%,50%);
		display: block;
		content: "";
		z-index: 10;
		transition: $bounce;
	}
	&:before {
		@include absolute(top 0 left 0);
	}
	&:after {
		@include absolute(bottom 0 left 0);
	}
	&:hover {
      color: $white;
		transform: scale(1.1);
		text-decoration: none !important;
	}
	&:active {
		transform: scale(.95);	
	}
	&:focus {
  	   box-shadow: $button-shadow-hover, 0 0 0 2px $white;
	}
	&.more {
      background: $clear;
      color: $black;
      border-radius: 0;
	   padding: .25rem .25rem;
	       text-decoration: none;
      &::after {
         content: "";
         width: calc(100% + .5rem);
         height: 4px;
         position: absolute;
         bottom: 0;
         left: -.25rem;
         z-index: -1;
         background: $black; 
      }
   }
   &.archive {
    margin-top: 5rem;
   }
   &.solid.yellow {
      color: $black !important;
   }
   &.solid.white {
      color: $black !important;
   }
   &.solid.red {
      color: $white !important;
   }
   &.outline {
      background-color: $clear !important;
      -webkit-box-shadow: 0 0 0 4px $yellow;
      box-shadow: 0 0 0 4px $yellow;
      color: $yellow !important;
      &.black {
         -webkit-box-shadow: 0 0 0 4px $black;
         box-shadow: 0 0 0 4px $black;
         color: $black !important;
      }
      &.dark-gray {
         -webkit-box-shadow: 0 0 0 4px $darkgray;
         box-shadow: 0 0 0 4px $darkgray;
         color: $darkgray !important;
      }
      &.red {
         -webkit-box-shadow: 0 0 0 4px $red;
         box-shadow: 0 0 0 4px $red;
         color: $red !important;
      }
      &.yellow {
         -webkit-box-shadow: 0 0 0 4px $yellow;
         box-shadow: 0 0 0 4px $yellow;
         color: $yellow !important;
      }
      &.white {
         -webkit-box-shadow: 0 0 0 4px $white;
         box-shadow: 0 0 0 4px $white;
         color: $white !important;
      }
   }
}
